import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { ModelSelect, BasicSelect, ModelListSelect } from 'vue-search-select';
import store from '../../../../store';
import asUploadEntry from './addTwoSixASUpload';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'twoSixASUpload',
  components: {
    ModelSelect,
    BasicSelect,
    ModelListSelect,
    commonHelper,
    asUploadEntry,
    store
  },
  watch: {
    currentPage: function() {
      this.getTwoASUploadList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTwoASUploadList();
    },
    finYear: function() {
      this.getFyStartDate();
      this.getFyEndDate();
    },
    quarter: function() {
      if (this.finYear.value && this.quarter.value) {
        this.getStartDateList();
      }
    }
  },
  data() {
    return {
      myArr: null,
      loader: null,
      shortNameSearch: null,
      excelRowDetails: null,
      orgNameSearch: null,
      parentOrgSearch: null,
      unsubscribe: null,
      showExcelMappingModal: false,
      showExcelUploadModal: false,
      uploadExcelModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      periodList: [],
      totalRows: null,
      legalEntityList: [],
      selectedLegalEntity: {
        value: null,
        text: null
      },
      panNumber: null,
      quarter: {
        value: null,
        text: null
      },
      financialYrStrtDate: null,
      financialYrEndDate: null,
      periodStartDateList: [],
      periodEndDateList: [],
      quarterList: [
        {
          value: '1',
          text: '1'
        },
        {
          value: '2',
          text: '2'
        },
        {
          value: '3',
          text: '3'
        },
        {
          value: '4',
          text: '4'
        }
      ],
      finYear: {
        value: null,
        text: null
      },
      finYearList: [
        {
          value: null,
          text: null
        }
      ],
      periodStartDate: null,
      periodEndDate: null,
      financeYears: [],
      tdsUploadData: [],
      tdsUploadFields: [
        {
          key: 'le_id',
          class: 'd-none'
        },
        {
          key: 'org_name',
          label: 'Legal Entity'
        },
        {
          key: 'pan_no',
          label: 'PAN No'
        },
        {
          key: 'fy_start_date',
          label: 'FY Start Date'
        },
        {
          key: 'fy_end_date',
          label: 'FY End Date'
        },
        {
          key: 'excel_name'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'user_name'
        }
      ]
    };
  },
  validations: {
    selectedLegalEntity: {
      text: {
        required
      }
    },
    finYear: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.getLegalEntity();
    this.getFinYearist();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showExcelMappingModal = true;
          this.excelRowDetails = null;
        }
        if (actionName === 'print') {
          this.showExcelUploadModal = true;
        }
        if (
          actionName === 'download' &&
          !this.showExcelUploadModal &&
          !this.showExcelMappingModal
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'twoSixASUpload/getTwoSixASList',
            'upload-two-six-as',
            () => (this.loader = false)
          );
        }
        if (actionName === 'upload') {
          this.uploadExcelModal = true;
        }
      }
    });
  },
  methods: {
    getTwoASUploadList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.selectedLegalEntity.value,
        pan_no: this.panNumber,
        financial_start_date: this.financialYrStrtDate,
        financial_end_date: this.financialYrEndDate
        // quarter: this.quarter.value,
        // period_start_date: this.periodStartDate,
        // period_end_date: this.periodEndDate
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/getTwoSixASList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.tdsUploadData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      }
    },
    getLegalEntity() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.user_id = store.state.auth.userId;
      payload.dependentVariable.res_id = store.state.shared.responsibilityId;
      this.loader = true;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.value_code
              };
            });
            this.legalEntityList = leagalEntity;
            this.selectedLegalEntity =
              leagalEntity && leagalEntity.length === 1
                ? leagalEntity[0]
                : this.selectedLegalEntity;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    onSelectOrgType(dtl) {
      this.selectedLegalEntity = {
        value: dtl.value,
        text: dtl.text
      };
      this.getPanNumForTwoSixUpload(this.selectedLegalEntity.value);
    },
    getPanNumForTwoSixUpload(leId) {
      this.$store
        .dispatch('twoSixASUpload/getPanNumForTwoSixUpload', leId)
        .then(response => {
          if (response.status === 200) {
            const pan = response.data.data.org_spec_detail;
            this.panNumber = pan;
          }
        });
    },
    // onSelectFinancialYr(item) {
    //   this.finYear = {
    //     value: item.value,
    //     text: item.text
    //   };
    //   this.getFyStartDate();
    //   this.getFyEndDate();
    // },
    getFyStartDate() {
      this.financeYears.forEach(element => {
        if (element.fy_display === this.finYear.text) {
          this.financialYrStrtDate = element.fy_start_date;
        }
      });
    },
    getFyEndDate() {
      this.financeYears.forEach(element => {
        if (element.fy_display === this.finYear.text) {
          this.financialYrEndDate = element.fy_end_date;
        }
      });
    },
    getFinYearist() {
      this.$store.dispatch('twoSixASUpload/getFinYearList').then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          this.financeYears = results;
          const financeYearList = results.map(type => {
            return {
              value: type.fy_display,
              text: type.fy_display
            };
          });
          this.finYearList = financeYearList;
        }
      });
    },
    // onSelectQuarter(event) {
    //   this.quarter = {
    //     value: event.value,
    //     text: event.value
    //   };
    //   if (this.quarter.value) {
    //     this.getStartDateList();
    //   }
    // },
    rowSelected(item) {
      this.excelRowDetails = item;
      this.openCloseExcelMappingModal(true);
    },

    getStartDateList() {
      const payload = {
        fy_start_date: this.financialYrStrtDate,
        fy_end_date: this.financialYrEndDate,
        qtr: this.quarter.value
      };
      this.$store
        .dispatch('twoSixASUpload/getPeriodStartList', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const startDateList = results;
            this.periodList = startDateList;
            const splitterIndex = this.periodList.period_name.indexOf('/');
            // const strtPrdArr = [];
            // const endPrdArr = [];

            // this.periodList.forEach(element => {
            this.periodStartDate = this.periodList.period_name.slice(
              0,
              splitterIndex
            );
            //   strtPrdArr.push({ strtPrd: strtPrd });
            // });
            // this.periodStartDateList = strtPrdArr.map(key => {
            //   return {
            //     value: key.strtPrd,
            //     text: key.strtPrd
            //   };
            // });
            // this.periodList.forEach(element => {
            this.periodEndDate = this.periodList.period_name.slice(
              splitterIndex + 1
            );
            //   endPrdArr.push({ endPrd: endPrd });
            // });
            // this.periodEndDateList = endPrdArr.map(key => {
            //   return {
            //     value: key.endPrd,
            //     text: key.endPrd
            //   };
            // });
          }
        });
    },
    seachASUpload() {
      this.getTwoASUploadList();
    },
    clearASUpload() {
      this.selectedLegalEntity = {
        value: null,
        text: null
      };
      this.panNumber = null;
      this.quarter = {
        value: null,
        text: null
      };
      this.finYear = {
        value: null,
        text: null
      };
      this.quarter = {
        value: null,
        text: null
      };
      this.periodStartDate = null;
      this.periodEndDate = null;
      this.periodStartDateList = [];
      this.periodEndDateList = [];
      this.tdsUploadData = [];
    },
    openCloseExcelMappingModal(flag) {
      this.showExcelMappingModal = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
