import { ModelSelect, BasicSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import store from '../../../../../store';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'addTwoSixASUpload',
  components: {
    ModelSelect,
    BasicSelect,
    DatePicker,
    commonHelper
  },
  props: ['excelRowDetails'],
  watch: {
    currentPage: function() {
      this.getRowDataById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRowDataById();
    },
    addFinYear: function() {
      this.getFyStartDate();
      this.getFyEndDate();
    },
    addQtr: function() {
      if (this.addFinYear.value && this.addQtr.value) {
        this.getStartDateList();
      }
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      tdsHrdId: null,
      loader: null,
      responseMsg: null,
      addLegalEntity: {
        value: null,
        text: null
      },
      periodList: [],
      tdsHdrID: null,
      editMode: false,
      addPanNo: null,
      totalRows: null,
      addFinYear: {
        value: null,
        text: null
      },
      addFinYearList: [],
      addQtr: {
        value: null,
        text: null
      },
      addQtrList: [
        {
          value: '1',
          text: '1'
        },
        {
          value: '2',
          text: '2'
        },
        {
          value: '3',
          text: '3'
        },
        {
          value: '4',
          text: '4'
        }
      ],
      periodStartDate: null,
      periodStartDateList: [],
      periodEndDate: null,
      periodEndDateList: [],
      financeYears: [],
      financialYrStrtDate: null,
      financialYrEndDate: null,
      legalEntityValue: [
        {
          value: null,
          text: null
        }
      ],
      lineTypeValue: [],
      data: [],
      fields: [
        {
          key: 'deductor_name'
        },
        {
          key: 'tan_no',
          label: 'TAN No'
        },
        {
          key: 'tds_section',
          label: 'Section'
        },
        {
          key: 'transaction_date'
        },
        {
          key: 'booking_status'
        },
        {
          key: 'booking_date'
        },
        {
          key: 'remark',
          label: 'Remarks'
        },
        {
          key: 'amount_paid'
        },
        {
          key: 'tax_deducted'
        },
        {
          key: 'tax_deposited'
        }
      ]
    };
  },
  mounted() {
    if (this.excelRowDetails) {
      this.tdsHdrID = this.excelRowDetails.tds_hdr_id;
      this.getRowDataById();
    } else {
      this.getTempDataList();
      this.editMode = true;
    }
    this.getLegalEntity();
    this.getFinYearist();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download' && this.excelRowDetails) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          downloadpayload.tds_hdr_id = this.tdsHdrID;
          this.downloadExcel.downloadData(
            downloadpayload,
            'twoSixASUpload/getRowDataById',
            'two-six-upload-financial-year',
            () => (this.loader = false)
          );
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditTempDataExcel();
        }
      }
    });
  },
  methods: {
    addEditTempDataExcel() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.addLegalEntity.value,
        pan_no: this.addPanNo,
        financial_start_date: this.financialYrStrtDate,
        financial_end_date: this.financialYrEndDate
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/postTempDataExcel', this.payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getRowDataById() {
      const payload = {
        tds_hdr_id: this.tdsHdrID,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/getRowDataById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
            this.data = results;
            this.addLegalEntity.text = this.excelRowDetails.org_name;
            this.addPanNo = this.excelRowDetails.pan_no;
            this.addFinYear.text =
              this.excelRowDetails.fy_start_date +
              ' - ' +
              this.excelRowDetails.fy_end_date;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // getDataByID(item) {
    //   this.data = [];
    //   this.data.push(item);
    //   this.addLegalEntity.text = item.org_name;
    //   this.addPanNo = item.pan_no;
    //   this.periodStartDate = item.period_start_date;
    //   this.periodEndDate = item.period_end_date;
    //   this.addFinYear.text = item.fy_start_date + ' - ' + item.fy_end_date;
    //   this.addQtr = {
    //     value: item.quarter,
    //     text: item.quarter
    //   };
    // },
    getTempDataList() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/getTempDataList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.data = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLegalEntity() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST,
        dependentVariable: {}
      };
      payload.dependentVariable.user_id = store.state.auth.userId;
      payload.dependentVariable.res_id = store.state.shared.responsibilityId;
      this.loader = true;
      this.$store
        .dispatch('shared/getCommonVSetData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data.data;
            const leagalEntity = results.map(type => {
              return {
                value: type.org_id,
                text: type.value_code
              };
            });
            this.legalEntityValue = leagalEntity;
            this.selectedLegalEntity =
              leagalEntity && leagalEntity.length === 1
                ? leagalEntity[0]
                : this.selectedLegalEntity;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    onSelectLeType(dtl) {
      this.addLegalEntity = {
        value: dtl.value,
        text: dtl.text
      };
      this.getPanNumForTwoSixUpload(this.addLegalEntity.value);
    },
    getPanNumForTwoSixUpload(leId) {
      this.$store
        .dispatch('twoSixASUpload/getPanNumForTwoSixUpload', leId)
        .then(response => {
          if (response.status === 200) {
            const pan = response.data.data.org_spec_detail;
            this.addPanNo = pan;
          }
        });
    },
    onSelectFinancialYr(item) {
      this.addFinYear = {
        value: item.value,
        text: item.text
      };
      this.getFyStartDate();
      this.getFyEndDate();
    },
    getFyStartDate() {
      this.financeYears.forEach(element => {
        if (element.fy_display === this.addFinYear.text) {
          this.financialYrStrtDate = element.fy_start_date;
        }
      });
    },
    getFyEndDate() {
      this.financeYears.forEach(element => {
        if (element.fy_display === this.addFinYear.text) {
          this.financialYrEndDate = element.fy_end_date;
        }
      });
    },
    getFinYearist() {
      this.$store.dispatch('twoSixASUpload/getFinYearList').then(response => {
        if (response.status === 200) {
          const results = response.data.data;
          this.financeYears = results;
          const financeYearList = results.map(type => {
            return {
              value: type.fy_display,
              text: type.fy_display
            };
          });
          this.addFinYearList = financeYearList;
        }
      });
    },
    onSelectQuarter(event) {
      this.addQtr = {
        value: event.value,
        text: event.value
      };
      if (this.addQtr.value) {
        this.getStartDateList();
      }
    },
    getStartDateList() {
      const payload = {
        fy_start_date: this.financialYrStrtDate,
        fy_end_date: this.financialYrEndDate,
        qtr: this.addQtr.value
      };
      this.$store
        .dispatch('twoSixASUpload/getPeriodStartList', payload)
        .then(response => {
          if (response.status === 200) {
            const results = response.data.data;
            const startDateList = results;
            this.periodList = startDateList;
            const splitterIndex = this.periodList.period_name.indexOf('/');
            // const strtPrdArr = [];
            // const endPrdArr = [];

            // this.periodList.forEach(element => {
            this.periodStartDate = this.periodList.period_name.slice(
              0,
              splitterIndex
            );
            //   strtPrdArr.push({ strtPrd: strtPrd });
            // });

            // this.periodStartDateList = strtPrdArr.map(key => {
            //   return {
            //     value: key.strtPrd,
            //     text: key.strtPrd
            //   };
            // });
            // this.periodList.forEach(element => {
            this.periodEndDate = this.periodList.period_name.slice(
              splitterIndex + 1
            );
            //   endPrdArr.push({ endPrd: endPrd });
            // });
            // this.periodEndDateList = endPrdArr.map(key => {
            //   return {
            //     value: key.endPrd,
            //     text: key.endPrd
            //   };
            // });
          }
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
